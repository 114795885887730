.react-multi-email.focused {
    border-color: #6366f1;
    background: #fff;
}

.react-multi-email [data-tag] {
    font-weight: 500;
    font-size: 1rem;
    color: black;
    line-height: 1.2;
    padding: 0.2em 0.6em;
}